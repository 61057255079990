@import "../../global.scss";

.portfolio {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 50px;

    @include mobile{
      font-size: 30px;
      overflow: hidden;
    }
  }

  ul {
    margin: 20px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;
    }
  }

  .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 60px 0px;

    @include mobile{
      width: 100%;
    }

    .item {
      width: 220px;
      height: 150px;
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all .5s ease;
      cursor: pointer;

      @include mobile{
        width: 130px;
        height: 100px;
      }

      img {
        width: 100%;
        max-height: 90%;
        border: 0;
        object-fit: cover;
      }

      &:hover {
        img {
          opacity: 0.6;
          z-index: 0;
        }
      }
    }
  }
}
