@import "../../global.scss";

.contact {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    flex-direction: column;
  }

  h1 {
    font-size: 50px;

    @include mobile{
      font-size: 30px;
      overflow: hidden;
    }
  }

  .bottomContainer{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    margin: 70px 0px;

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      min-height: 50px;

      .socialContainer {
        display: flex;
        align-items: center;

        .icon {
          font-size: 48px;
          margin-right: 5px;
          color: white;
        }
      }
    }

    h2 {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      overflow: hidden;
  
      @include mobile{
        font-size: 20px;
      }
    }

    .bottom {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        max-height: 50%;
      }
    }
  }
}
